import { NavLink } from "react-router-dom";
import ItodoImage from "../../elements/itodo-img";


function Footer() {
    // Get the current year
    const currentYear = new Date().getFullYear();

    return (
        <>
            <footer className="site-footer" style={{ backgroundColor: "black" , color:"white"}}>
                {/* FOOTER BLOCKS START */}
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            {/* ABOUT COMPANY */}
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                <div className="widget widget_about">
                                    <div className=" clearfix p-b15 p-t20">
                                        <NavLink to="/index" className="sx-text-primary ">
                                        <ItodoImage 
                      src="images/fpr.png" 
                      alt="" 
                      style={{
                        width: '190px', 
                        // height: '130px', 
                        borderRadius: '60%', 
                        objectFit: 'cover' 
                      }} />
                                        </NavLink>
                                    </div>
                                    <p>
                                        Our goal is to help our clients increase their online visibility, attract more traffic, and generate leads. We believe in building long-term partnerships with our clients and delivering results that exceed their expectations.
                                    </p>
                                    <div className="widget_about-call-section">
                                        <div className="sx-f-call-icon">
                                            <i className="flaticon-telephone" />
                                        </div>
                                        <div className="sx-f-call-section">
                                            <span >Contact us 9 to 5</span>
                                            <a href="tel:(737) 282- 8861">(737) 282- 8861</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* EXPLORE LINKS */}
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 col" >
                                <div className="widget widget_services" >
                                    <h4 className="sx-f-title" style={{color:"#fdcb32"}}>Our Services</h4>
                                    <ul >
                                        <li><NavLink to="/services/Search-Engine-Optimization-Services" style={{color:"white"}}>Search Engine Optimization Services</NavLink></li>
                                        <li><NavLink to="/services/Website-Development-Services" style={{color:"white"}}>Web Design and Development Services</NavLink></li>
                                        <li><NavLink to="/services/Graphic-Designing-Services" style={{color:"white"}}>Graphic Designing Services</NavLink></li>
                                        <li><NavLink to="/services/Google-Ads-Services" style={{color:"white"}}>Google Ads Services</NavLink></li>
                                        <li><NavLink to="/services/Content-Writing-Services" style={{color:"white"}}>Content Writing</NavLink></li>
                                        <li><NavLink to="/services/Social-Media-Optimization-Services" style={{color:"white"}}>SocialMedia Optimization</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                            {/* USEFUL LINK */}
                            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-6 col">
                                <div className="widget widget_services">
                                    <h4 className="sx-f-title" style={{color:"#fdcb32"}}>Useful Links</h4>
                                    <ul>
                                        <li><NavLink to="/services" style={{color:"white"}}>Services</NavLink></li>
                                        <li><NavLink to="/pricing" style={{color:"white"}}>Pricing</NavLink></li>
                                        <li><NavLink to="/about-us" style={{color:"white"}}>About Us</NavLink></li>
                                        <li><NavLink to="/contact-us" style={{color:"white"}}>Contact Us</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                            {/* SOLUTIONS */}
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 col-12 col footer-col-3">
                                <div className="widget widget_info">
                                    <h4 className="sx-f-title" style={{color:"#fdcb32"}}>Solutions</h4>
                                    <ul>
                                        <li>
                                            <div className="widget-info-icon">
                                                <i className="flaticon-phone" />
                                            </div>
                                            <div className="widget-info-detail">
                                                <a href="tel:(737) 282- 8861" style={{color:"white"}}>(737) 282- 8861</a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="widget-info-icon">
                                                <i className="flaticon-email" />
                                            </div>
                                            <div className="widget-info-detail">
                                                <a href="mailto:info@firstpagerankerz.com" style={{color:"white"}}>info@firstpagerankerz.com</a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="widget-info-icon">
                                                <i className="flaticon-maps-and-flags" />
                                            </div>
                                            <div className="widget-info-detail">
                                                <p>30 N Gould St STE 28894 Sheridan WY, Wyoming, United States 82801</p>
                                            </div>
                                        </li>
                                       
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sx-f-bottom-section" style={{backgroundColor:"#fdcb32"}} >
                    <div className="col-md-12">
                        <div className="section-content text-center ">
                            <ul className="icon-list ">
                                <div className="icon-container d-flex justify-content-center gap-3">
                                    {/* Google SVG */}
                                    <svg className="popout" xmlns="http://www.w3.org/2000/svg" height="44" viewBox="0 0 24 24" width="34">
                                        <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
                                        <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/>
                                        <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/>
                                        <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/>
                                        <path d="M1 1h22v22H1z" fill="none"/>
                                    </svg>
                                    <ItodoImage src="images/icons8-facebook-48.png" alt="image" />
                                    <ItodoImage src="images/icons8-google-shopping-48.png" alt="image" />
                                   <ItodoImage src="images/icons8-outlook-48.png" alt="image" />

                                </div>
                                <p className="ariel" style={{color:"black"}}>Add partner | Recognized by Google</p>
                            </ul>
                        </div>
                    </div>
                    
                    <div className="sx-f-copy" style={{color:"black"}}>
                        © {currentYear} by <NavLink to="" style={{color:"black"}}>FirstPageRankerz.</NavLink> All rights reserved.
                    </div>
                </div>
                {/* FOOTER COPYRIGHT */}
            </footer>
        </>
    );
}

export default Footer;

import { useState } from "react";
import emailjs from "emailjs-com";
import { bannerData } from "../../../globals/banner";
import Banner from "../../sections/common/banner";
import "./contact.css"

function ContactUsPage() {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        phone: '',
        purpose: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.send(
            'service_bktyazh', // replace with your service ID
            'template_w84fxlk', // replace with your template ID
            formData,
            'bM4f8r1teKyCl-Oc6' // replace with your user ID
        ).then((result) => {
            alert('Message Sent Successfully!');
        }, (error) => {
            alert('An error occurred, Please try again');
        });

        // Clear the form after submission
        setFormData({
            username: '',
            email: '',
            phone: '',
            purpose: '',
            message: ''
        });
    };

    return (
        <>
            <Banner _data={bannerData.contact} />

            {/* Contact us START */}
            <div className="section-full p-t110 p-b80 sx-bg-white sx-ourteam-outer " style={{backgroundColor:"#fdcb32"}}>
                <div className="container" style={{backgroundColor:"black"}}>
                    {/* GOOGLE MAP & CONTACT FORM */}
                    <div className="section-content" >
                        {/* CONTACT FORM*/}
                        <div className="row justify-content-center" >
                            <div className="col-lg-8 col-md-12  m-b30">
                                <div className="contact-info">
                                    {/* TITLE START */}
                                    <div className="section-head left">
                                        <div className="sx-head-s-title ">Contact Us</div>
                                        <div className="sx-head-l-title">
                                            <h2 className="sx-title" style={{color:"white"}}>Get in touch with us for expert solutions tailored to your needs </h2>
                                        </div>
                                    </div>
                                    {/* TITLE END */}
                                    <div className="row sx-contact-form-icon-bx">
                                        <div className="col-lg-6 col-md-6 m-b30">
                                            <div className="sx-icon-bx-5">
                                                <div className="sx-media">
                                                    <i className="flaticon-telephone" />
                                                </div>
                                                <div className="sx-icon-bx-info" >
                                                <p className="sx-icon-bx-title-info" style={{color:"white"}}>Contact Phone</p>
                                                 <h6><a href="tel:(737) 282- 8861" style={{color:"white"}}>(737) 282- 8861</a></h6> 
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 m-b30">
                                            <div className="sx-icon-bx-5">
                                                <div className="sx-media">
                                                    <i className="flaticon-email-2" />
                                                </div>
                                                <div className="sx-icon-bx-info">
                                                <p className="sx-icon-bx-title-info" style={{color:"white"}}>Contact Mail</p>
                                                    <a href="mailto:info@firstpagerankerz.com" className="sx-icon-bx-title" style={{color:"white"}}>info@firstpagerankerz.com</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 m-b30">
                                            <div className="sx-icon-bx-5">
                                                <div className="sx-media">
                                                    <i className="flaticon-map-location" />
                                                </div>
                                                <div className="sx-icon-bx-info">
                                                    <p className="sx-icon-bx-title-info" style={{color:"white"}}>Contact Location </p>
                                                    <p className="sx-icon-bx-title" style={{color:"white"}}>30 N Gould St STE 28894 Sheridan WY, Wyoming, United States 82801</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 m-b30">
                                            <div className="sx-icon-bx-5">
                                                <div className="sx-media">
                                                    <i className="flaticon-clock" />
                                                </div>
                                                <div className="sx-icon-bx-info">
                                                   <p className="sx-icon-bx-title-info" style={{color:"white"}}>Office Time</p>
                                                    <p className="sx-icon-bx-title" style={{color:"white"}}>
                                                    Mon-Fri 9.00 AM to 5.00PM.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-6 col-md-6 m-b30">
                                            <div className="sx-icon-bx-5">
                                                <div className="sx-media">
                                                    <i className="flaticon-map-location" />
                                                </div>
                                                <div className="sx-icon-bx-info">
                                                    <a href="#" className="sx-icon-bx-title-info">Contact Location Canada</a>
                                                    <a href="#" className="sx-icon-bx-title">39 weatherall ave, Cambridge, ON, Canada, Ontario</a>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-4 col-md-12 m-b30">
                                <div className="contact-home1-right">
                                    <div className="contact-home-1-form sx-bg-light">
                                        <h4 className="sx-title">Get In Touch</h4>
                                        <form className="cons-contact-form2 form-transparent" method="post" onSubmit={sendEmail}>
                                            <div className="input input-animate">
                                                <input type="text" name="username" id="name" required placeholder="Your Name" value={formData.username} onChange={handleChange} />
                                                <span className="spin" />
                                            </div>
                                            <div className="input input-animate">
                                                <input type="email" name="email" id="email" required placeholder="Your Email" value={formData.email} onChange={handleChange} />
                                                <span className="spin" />
                                            </div>
                                            <div className="input input-animate">
                                                <input type="number" name="phone" id="Phone" required placeholder="Phone Number" value={formData.phone} onChange={handleChange} />
                                                <span className="spin" />
                                            </div>
                                            <div className="input input-animate">
                                                <input type="text" name="purpose" id="Phone" required placeholder="Purpose Of Contact" value={formData.purpose} onChange={handleChange} />
                                                <span className="spin" />
                                            </div>
                                            <div className="input textarea input-animate">
                                                <textarea name="message" id="message" required placeholder="Your Message" value={formData.message} onChange={handleChange} />
                                                <span className="spin" />
                                            </div>
                                            <div className="sx-btn-center text-center p-t10">
                                                <button type="submit" className="site-button sx-btn-primary icon sx-btn-lg">
                                                    <i className="fa  fa-long-arrow-right" />
                                                    Send Message
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div> */}
                  {/* PayPal Form */}
    <div className="paypal-section" style={{ marginTop: '20px', textAlign: 'center' }}>
        <form 
            action="https://www.paypal.com/ncp/payment/7G2HW5YCMYVTU" 
            method="post" 
            target="_top"
            style={{ display: 'inline-grid', justifyItems: 'center', alignContent: 'start', gap: '0.5rem' }}
        >
            <input className="pp-7G2HW5YCMYVTU" type="submit" value="Buy Now" />
            <img src="https://www.paypalobjects.com/images/Debit_Credit_APM.svg" alt="Payment Methods" />
            <section>
                Powered by 
                <img 
                    src="https://www.paypalobjects.com/paypal-ui/logos/svg/paypal-wordmark-color.svg" 
                    alt="PayPal" 
                    style={{ height: '0.875rem', verticalAlign: 'middle' }} 
                />
            </section>
        </form>
    </div>
       </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactUsPage;
